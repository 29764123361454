<template>
<ValidationObserver ref="observer" v-slot="{ validate  }">
  <b-form @submit.stop.prevent="validate().then(submit)">
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full" v-model="tabIndexComponent">
        <b-tab title="Geral">
          <GeneralPharmaceuticalsInformation @imagem="Imagem = $event" :viewModel="viewModel" class="mt-4" />
        </b-tab>
        <b-tab title="Ensaios">
          <EssaysInformationPharmaceuticals
            class="mt-4"
            :showFormCreate="showFormCreate"
            @showForm="(event) => $emit('showForm',event)"
            v-model="viewModel"
          />
        </b-tab>
        <b-tab title="Valores">
          <ValuePharmaceuticalsInformation    v-model="viewModel" class="mt-4" />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
    </div>
    <footer
      v-if="tabIndexComponent !== 1"
      class="w-full flex items-center justify-start gap-5 my-10"
    >
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        type="submit"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="() => $router.push('/farmaceuticas')"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextCreate')"
    />
    <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
    />
    <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
    />
  </div>
</b-form>
</ValidationObserver>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPharmaceuticalsInformation from "./GeneralPharmaceuticalsInformation";
import EssaysInformationPharmaceuticals from "./EssaysInformationPharmaceuticals";
import ValuePharmaceuticalsInformation from "./ValuePharmaceuticalsInformation";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ManagePharmaceuticalsCreate",
  components: {
    BTabs,
    BTab,
    BForm,
    GeneralPharmaceuticalsInformation,
    EssaysInformationPharmaceuticals,
    ValuePharmaceuticalsInformation,
    Button,
    Modal,
    ValidationObserver
  },
  props: {
    tabIndex: {
      type: Number,
    },
    showFormCreate: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalErroSalvar: null,
      modalError:false,
      modalIsOpen: false,
      tabIndexComponent: this.tabIndex,
      Imagem:null,
      viewModel: {
        TipoForma: null,
        Descricao: null,
        SelecionaQtdeSugerida: false,
        MultiplicaComposicao:false,
        HomeopatiaLiquida:false,
        DeduzirQtdeVeiculo:false,
        ConverteVolumeEmbalagem: false,
        CalculoEmbalagem: null,
        Uso: null,
        TipoUso: null,
        ProcedimentoOperacionalPadrao: null,
        ImprimeCamposAnaliseProduto: false,
        Validade: null,
        MlGotas: null,
        ManipuladorID: null,
        QuantidadeFormulasHora: null,
        FatorPerda:null,
        DescricaoRotulo: null,
        PercentualQspMinimo: null,
        VeiculoID: null,
        InativarForma: false,
        LabEspCustos:null,
        AtivaPesagemForma: false,
        CalcularDensidade: false,
        ImprimirUnidadeRotulo: false,
        SelecionaVolumeAutomatico:false,
        AtivaFatorPerdaQSP: false,
        FormaFarmaceuticaEnsaio: [],
        ValorMinimoForma: null,
        CustoAdicionalForma: null,
        NcmID: null,
        FormaFarmaceuticaFaixa: [],
        FormaFarmaceuticaMargem:[],
      }
    };
  },
  methods: {
    closeModal (event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalErroSalvar = false,
        this.modalError = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    async submit () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.modalError = true;
        return
      }
      this.$http({
        url: "formaFarmaceutica/novo",
        data: this.viewModel,
        method: "POST"
      }).then((response) => {
        if(this.Imagem != null) this.enviarImage(response.data)
          
          this.modalIsOpen = true;
          setTimeout(() => this.$router.push({name:"farmaceuticas"}), 3000);
      }).catch(() => this.modalErroSalvar = true)
    },
    enviarImage (id) {
      let formData = new FormData();
      formData.append('file', this.Imagem);
      this.$http.post(`formaFarmaceutica/cad-image/${id}`, formData)
    }
  },
  watch: {
    tabIndexComponent() {
      this.$emit("update:tabIndex", this.tabIndexComponent);
    },
  },
};
</script>
