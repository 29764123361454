<template>
  <section class="w-full">
    <header-register
      v-if="tabIndex === 1"
      :showButtonView="true"
      :showFormCreate="showFormCreate"
      :changeRoutes="changeRoutes"
      :click="() => showFormCreate = !showFormCreate"
      >{{ $t("sectionPharmaceuticals.titlePharmaceuticals") }}
    </header-register>
    <header-register v-else :showButtonView="false"
    >{{ $t("sectionPharmaceuticals.titlePharmaceuticals") }}
  </header-register>
  <main class="w-full">
    <ManagePharmaceuticalsCreate
        :tabIndex.sync="tabIndex"
        @showForm="(event) => showFormCreate = event"
        :showFormCreate="showFormCreate"
      />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManagePharmaceuticalsCreate from "@/components/farmaceuticas/components/create/ManagePharmaceuticalsCreate.vue";

export default {
  name: "PharmaceuticalsCreate",
  components: {
    HeaderRegister,
    ManagePharmaceuticalsCreate,
  },
  data() {
    return {
      tabIndex: 0,
      showFormCreate: false,
      routeUrl: "",
    };
  },
  methods: {
    changeRoutes() {
      const url = "/farmaceuticas/ensaios/create";
      history.pushState(null, null, url);
    },
  },
  watch: {
    tabIndex() {
      if (this.tabIndex !== 1) {
        this.showFormCreate = false;
        const url = "/farmaceuticas/create";
        history.pushState(null, null, url);
      }
    },
  },
};
</script>
