var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-form',[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('section',{staticClass:"w-full flex flex-col"},[_c('div',{staticClass:"w-full flex gap-5 justify-between mb-2"},[_c('input-required',{staticClass:"col-9 padding-0",attrs:{"label":_vm.$t('sectionPharmaceuticals.description'),"rules":{required:true, max:50},"max":"50"},model:{value:(_vm.viewModel.Descricao),callback:function ($$v) {_vm.$set(_vm.viewModel, "Descricao", $$v)},expression:"viewModel.Descricao"}}),_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.inativarForma'
                      )},model:{value:(_vm.viewModel.InativarForma),callback:function ($$v) {_vm.$set(_vm.viewModel, "InativarForma", $$v)},expression:"viewModel.InativarForma"}})],1)],1),_c('div',{staticClass:"w-full flex border__top border__bottom",staticStyle:{"font-size":"small"}},[_c('div',{staticClass:"flex col-4 padding-0"},[_c('div',{staticClass:" 2xl:w-20 p-2 col-3 padding-0 border__right",class:{
              }},[_c('span',{staticClass:"text-red-500 required"},[_vm._v(_vm._s(_vm.$t("sectionPharmaceuticals.type"))),_c('span',{staticStyle:{"color":"#cf0209"}},[_vm._v(" *")])])]),_c('div',{staticClass:"col-9 padding-right-0  border__right"},[_c('radioButton',{attrs:{"options":_vm.options,"label":_vm.$t('sectionPharmaceuticals.type'),"rules":"required","stacked":""},model:{value:(_vm.viewModel.TipoForma),callback:function ($$v) {_vm.$set(_vm.viewModel, "TipoForma", $$v)},expression:"viewModel.TipoForma"}})],1)]),_c('div',{staticClass:"col-8 padding-0"},[_c('div',{staticClass:"flex-1 flex flex-col col-12 padding-0 border__right",class:{
                border__right: _vm.$mq.desktop,
              }},[_c('div',{staticClass:"flex flex-col p-2 border__bottom gap-1 "},[_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.selectSuggestedQuantity'
                    )},model:{value:(_vm.viewModel.SelecionaQtdeSugerida),callback:function ($$v) {_vm.$set(_vm.viewModel, "SelecionaQtdeSugerida", $$v)},expression:"viewModel.SelecionaQtdeSugerida"}}),_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.multiplyComposition'
                    ),"disabled":_vm.viewModel.TipoForma == '5' ||
                               _vm.viewModel.TipoForma == '6'?
                               false : true,"value":_vm.viewModel.TipoForma == '5' ||
                            _vm.viewModel.TipoForma == '6'?
                               _vm.viewModel.MultiplicaComposicao : _vm.viewModel.MultiplicaComposicao =  false},model:{value:(_vm.viewModel.MultiplicaComposicao),callback:function ($$v) {_vm.$set(_vm.viewModel, "MultiplicaComposicao", $$v)},expression:"viewModel.MultiplicaComposicao"}})],1),_c('div',{staticClass:"flex flex-col p-2 border__bottom gap-1"},[_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.liquidHomeopathy'
                    ),"disabled":_vm.viewModel.TipoForma == '2' ?
                               false : true,"value":_vm.viewModel.TipoForma == '2' ?
                               _vm.viewModel.HomeopatiaLiquida : _vm.viewModel.HomeopatiaLiquida =  false},model:{value:(_vm.viewModel.HomeopatiaLiquida),callback:function ($$v) {_vm.$set(_vm.viewModel, "HomeopatiaLiquida", $$v)},expression:"viewModel.HomeopatiaLiquida"}}),_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.doNotDeductFromTheVehicle'
                    ),"disabled":_vm.viewModel.TipoForma == '2' ?
                               false : true,"value":_vm.viewModel.TipoForma == '2' ?
                               _vm.viewModel.DeduzirQtdeVeiculo : _vm.viewModel.DeduzirQtdeVeiculo =  false},model:{value:(_vm.viewModel.DeduzirQtdeVeiculo),callback:function ($$v) {_vm.$set(_vm.viewModel, "DeduzirQtdeVeiculo", $$v)},expression:"viewModel.DeduzirQtdeVeiculo"}})],1),_c('div',{staticClass:"flex flex-col p-2 border__bottom"},[_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.convertPackageVolume'
                    )},model:{value:(_vm.viewModel.ConverteVolumeEmbalagem),callback:function ($$v) {_vm.$set(_vm.viewModel, "ConverteVolumeEmbalagem", $$v)},expression:"viewModel.ConverteVolumeEmbalagem"}})],1)]),_c('div',{staticClass:"w-full xl:w-auto p-2 border__right border__bottom",class:{
              }},[_c('span',{staticClass:"text-red-500"},[_vm._v(" Cálculo da embalagem "),(_vm.viewModel.TipoForma == '5' || _vm.viewModel.TipoForma == '6')?_c('span',[_vm._v(" * ")]):_vm._e()])]),_c('div',{staticClass:"flex flex-col p-2 FormsRadio border__right"},[_c('radioButton',{attrs:{"options":_vm.optionsCalculoEmbalagem,"label":"Cálculo da embalagem","stacked":"","rules":{required : _vm.viewModel.TipoForma == '5' ||
                                    _vm.viewModel.TipoForma == '6'},"disabled":_vm.viewModel.TipoForma == '5' ||
                          _vm.viewModel.TipoForma == '6'?
                          false : true,"value":_vm.viewModel.TipoForma == '5' ||
                        _vm.viewModel.TipoForma == '6'?
                        _vm.viewModel.CalculoEmbalagem : _vm.viewModel.CalculoEmbalagem =  null},model:{value:(_vm.viewModel.CalculoEmbalagem),callback:function ($$v) {_vm.$set(_vm.viewModel, "CalculoEmbalagem", $$v)},expression:"viewModel.CalculoEmbalagem"}})],1)])]),_c('div',{staticClass:"w-full flex border__bottom",class:{
            }},[_c('div',{staticClass:"p-2 col-6 ",class:{
                border__bottom: _vm.$mq.notebook,
              },staticStyle:{"padding-left":"0!important"}},[_c('input-default',{attrs:{"label":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.use'),"rules":{max:20},"max":"20"},model:{value:(_vm.viewModel.Uso),callback:function ($$v) {_vm.$set(_vm.viewModel, "Uso", $$v)},expression:"viewModel.Uso"}})],1),_c('div',{staticClass:"w-1/2 flex p-2 FormsRadio border__right"},[_c('div',{staticClass:"border__right w-full 2xl:w-60 text-red-500 col-5 mb-2 text-center  flex 2xl:items-start"},[_c('span',[_vm._v(_vm._s(_vm.$t( "sectionPharmaceuticals.generalPharmaceuticals.typeOfUse" ))+" *")])]),_c('radioButton',{staticClass:"col-7",attrs:{"options":_vm.optionsTipoUso,"label":"Tipo Uso","stacked":"","rules":"required"},model:{value:(_vm.viewModel.TipoUso),callback:function ($$v) {_vm.$set(_vm.viewModel, "TipoUso", $$v)},expression:"viewModel.TipoUso"}})],1)]),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-12 gap-4 mt-3"},[_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('input-default',{attrs:{"label":"P.O.P","rules":{max:10},"mask":"XXXXXXXXXX","max":"10"},model:{value:(_vm.viewModel.ProcedimentoOperacionalPadrao),callback:function ($$v) {_vm.$set(_vm.viewModel, "ProcedimentoOperacionalPadrao", $$v)},expression:"viewModel.ProcedimentoOperacionalPadrao"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-6 "},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.printProductAnalysisFields'
                      )},model:{value:(_vm.viewModel.ImprimeCamposAnaliseProduto),callback:function ($$v) {_vm.$set(_vm.viewModel, "ImprimeCamposAnaliseProduto", $$v)},expression:"viewModel.ImprimeCamposAnaliseProduto"}}),_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.SelecionarVolumeAutomatico'
                      ),"disabled":_vm.viewModel.TipoForma == '5' ||
                               _vm.viewModel.TipoForma == '6'?
                               false : true,"value":_vm.viewModel.TipoForma == '5' ||
                            _vm.viewModel.TipoForma == '6'?
                               _vm.viewModel.SelecionaVolumeAutomatico : _vm.viewModel.SelecionaVolumeAutomatico =  false},model:{value:(_vm.viewModel.SelecionaVolumeAutomatico),callback:function ($$v) {_vm.$set(_vm.viewModel, "SelecionaVolumeAutomatico", $$v)},expression:"viewModel.SelecionaVolumeAutomatico"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid justify-between grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.validityDays'
                      ),"hasText":true,"text":"(dias)","type":"number","mask":"XXXX","rules":{positive:true,integer:true}},model:{value:(_vm.viewModel.Validade),callback:function ($$v) {_vm.$set(_vm.viewModel, "Validade", $$v)},expression:"viewModel.Validade"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.quantityMlDrops'
                      ),"hasText":true,"text":"(ML/Gotas)","rules":{positive:true ,decimal:[10,2], max:8},"type":"number"},model:{value:(_vm.viewModel.MlGotas),callback:function ($$v) {_vm.$set(_vm.viewModel, "MlGotas", $$v)},expression:"viewModel.MlGotas"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-7"},[_c('input-default',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.FatorPerda'
                      ),"type":"number","rules":{positive:true,decimal:[24,5],max:24},"disabled":_vm.viewModel.TipoForma == null ||
                               _vm.viewModel.TipoForma == '2' ||
                               _vm.viewModel.TipoForma == '3' ||
                               _vm.viewModel.TipoForma == '4' || 
                               _vm.viewModel.TipoForma == '7'?
                               true : false,"value":_vm.viewModel.TipoForma == null ||
                            _vm.viewModel.TipoForma == '2' ||
                            _vm.viewModel.TipoForma == '3' ||
                            _vm.viewModel.TipoForma == '4' || 
                            _vm.viewModel.TipoForma == '7'?
                            _vm.viewModel.FatorPerda = null : _vm.viewModel.FatorPerda},model:{value:(_vm.viewModel.FatorPerda),callback:function ($$v) {_vm.$set(_vm.viewModel, "FatorPerda", $$v)},expression:"viewModel.FatorPerda"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.ImprimirUnidadeRotulo'
                      ),"disabled":_vm.viewModel.TipoForma == '3' ?
                               false : true,"value":_vm.viewModel.TipoForma == '3' ?
                               _vm.viewModel.ImprimirUnidadeRotulo : _vm.viewModel.ImprimirUnidadeRotulo =  false},model:{value:(_vm.viewModel.ImprimirUnidadeRotulo),callback:function ($$v) {_vm.$set(_vm.viewModel, "ImprimirUnidadeRotulo", $$v)},expression:"viewModel.ImprimirUnidadeRotulo"}}),_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                          'sectionPharmaceuticals.generalPharmaceuticals.AplicaFatorPerdaQSP'
                        ),"disabled":_vm.viewModel.TipoForma == '2' ||
                               _vm.viewModel.TipoForma == '3' ||
                               _vm.viewModel.TipoForma == '4' || 
                               _vm.viewModel.TipoForma == '7'?
                               true : false,"value":_vm.viewModel.TipoForma == '2' ||
                            _vm.viewModel.TipoForma == '3' ||
                            _vm.viewModel.TipoForma == '4' || 
                            _vm.viewModel.TipoForma == '7'?
                            _vm.viewModel.AtivaFatorPerdaQSP = null : _vm.viewModel.AtivaFatorPerdaQSP},model:{value:(_vm.viewModel.AtivaFatorPerdaQSP),callback:function ($$v) {_vm.$set(_vm.viewModel, "AtivaFatorPerdaQSP", $$v)},expression:"viewModel.AtivaFatorPerdaQSP"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('Dropdown',{attrs:{"Items":_vm.dropdownItemsManipulador,"valueDefault":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.manipulator'),"label":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.manipulator')},model:{value:(_vm.viewModel.ManipuladorID ),callback:function ($$v) {_vm.$set(_vm.viewModel, "ManipuladorID", $$v)},expression:"viewModel.ManipuladorID "}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.hourFormulas'
                      ),"type":"number","rules":{positive:true,integer:true}},model:{value:(_vm.viewModel.QuantidadeFormulasHora),callback:function ($$v) {_vm.$set(_vm.viewModel, "QuantidadeFormulasHora", $$v)},expression:"viewModel.QuantidadeFormulasHora"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.labelDescription'
                      ),"rules":{max:50},"max":"50","disabled":_vm.viewModel.TipoForma == '2' ||
                               _vm.viewModel.TipoForma == '3' ||
                               _vm.viewModel.TipoForma == '7' ?
                               false : true,"value":_vm.viewModel.TipoForma == '2' ||
                               _vm.viewModel.TipoForma == '3' ||
                               _vm.viewModel.TipoForma == '7' ?
                               _vm.viewModel.DescricaoRotulo : _vm.viewModel.DescricaoRotulo =  null},model:{value:(_vm.viewModel.DescricaoRotulo),callback:function ($$v) {_vm.$set(_vm.viewModel, "DescricaoRotulo", $$v)},expression:"viewModel.DescricaoRotulo"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.minimumQsp'
                      ),"hasText":true,"text":"%","type":"number","rules":{positive:true,decimal:[24,5]},"disabled":_vm.viewModel.TipoForma == '5' ||
                               _vm.viewModel.TipoForma == '6'?
                               false : true,"value":_vm.viewModel.TipoForma == '5' ||
                            _vm.viewModel.TipoForma == '6'?
                               _vm.viewModel.PercentualQspMinimo : _vm.viewModel.PercentualQspMinimo =  null},model:{value:(_vm.viewModel.PercentualQspMinimo),callback:function ($$v) {_vm.$set(_vm.viewModel, "PercentualQspMinimo", $$v)},expression:"viewModel.PercentualQspMinimo"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('Dropdown',{attrs:{"Items":_vm.dropdownItemsVeiculo,"valueDefault":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.vehicle'),"label":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.vehicle'),"disabled":_vm.viewModel.TipoForma == '2' ||
                                _vm.viewModel.TipoForma == '3' ||
                                _vm.viewModel.TipoForma == '7' ?
                                false : true,"value":_vm.viewModel.TipoForma == '2' ||
                                _vm.viewModel.TipoForma == '3' ||
                                _vm.viewModel.TipoForma == '7' ?
                                _vm.viewModel.Veiculo : _vm.viewModel.Veiculo =  null},model:{value:(_vm.viewModel.Veiculo),callback:function ($$v) {_vm.$set(_vm.viewModel, "Veiculo", $$v)},expression:"viewModel.Veiculo"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('div',{staticClass:"flex flex-col lg:pl-5"},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                          'sectionPharmaceuticals.generalPharmaceuticals.formMonitoredWeighing'
                        )},model:{value:(_vm.viewModel.AtivaPesagemForma),callback:function ($$v) {_vm.$set(_vm.viewModel, "AtivaPesagemForma", $$v)},expression:"viewModel.AtivaPesagemForma"}}),_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                          'sectionPharmaceuticals.generalPharmaceuticals.calculateDensity'
                        )},model:{value:(_vm.viewModel.CalcularDensidade),callback:function ($$v) {_vm.$set(_vm.viewModel, "CalcularDensidade", $$v)},expression:"viewModel.CalcularDensidade"}})],1)])])]),_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('div',[_c('input-default',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.CodLab'
                    ),"rules":{max:2},"max":"2"},model:{value:(_vm.viewModel.LabEspCustos),callback:function ($$v) {_vm.$set(_vm.viewModel, "LabEspCustos", $$v)},expression:"viewModel.LabEspCustos"}})],1)])])])]),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('div',{staticClass:"w-80 flex flex-col",class:{ 'w-full': _vm.$mq.mobile }},[_c('div',{staticClass:"imagePreviewWrapper background__color button__border__bottom h-72 rounded relative",style:({ 'background-image': ("url(" + _vm.imagePreview + ")") })},[_c('button',{staticClass:"absolute bottom-1 -right-1",on:{"click":function($event){$event.preventDefault();return _vm.clearImagePreview.apply(null, arguments)}}},[_c('TrashIcon',{staticClass:"w-12"})],1)]),_c('div',{staticClass:"flex flex-col"},[_c('input',{ref:"fileInput",staticClass:"hidden",attrs:{"type":"file"},on:{"input":_vm.pickFile}}),_c('button',{staticClass:"background__color w-full h-12 rounded font-bold text-base button__border__bottom",on:{"click":function($event){$event.preventDefault();return _vm.selectImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t( "sectionPharmaceuticals.generalPharmaceuticals.chooseAnImage" ))+" ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }