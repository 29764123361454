<template>
  <section>
    <b-form v-if="showFormCreate">
      <EssayForm formAction="create" v-model="value" @showFormCreate="(event) => $emit('showForm',event)" />
    </b-form>
    <div v-else>
      <ListEssays showActions  v-model="value"/>
    </div>
  </section>
</template>

<script type="module">
import EssayForm from "@core/components/commons/essayForm/EssayForm";

import ListEssays from "@core/components/commons/essayForm/ListEssays.vue";

import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationPharmaceuticals",
  components: {
    BForm,
    EssayForm,
    ListEssays,
  },
  props: {
    showFormCreate: {
      type: Boolean,
    },
    tabIndex: {
      type: Number,
    },
    value: {
      required: true,
      type:Object,
    }
  },
  methods: {
    changeRoutes(id) {
      const url = `/farmaceuticas/ensaios/edit/${id}`;
      history.pushState(null, null, url);
    },
  },
  watch: {
    tabIndex() {
      if (this.tabIndex !== 1) {
        const url = `/farmaceuticas/edit/${1}`;
        history.pushState(null, null, url);
      }
    },
  },
};
</script>
